import { Component,OnInit, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as confetti from 'canvas-confetti';
import { ModalAniversarioService } from '../../components/modal-aniversario/modal-anniversario.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public clicked = false;

  constructor(private translate: TranslateService, private renderer2: Renderer2,
    private elementRef: ElementRef,
    private modalService: ModalAniversarioService) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.fiesta();
    setTimeout(()=>{
      this.modalService.mostrarModal();
    }, 1000);
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ejemploConfeti() {
    const canvas = this.renderer2.selectRootElement('#miCanvas');
    const myConfetti = confetti.create(canvas, {
      resize: true,
      particleCount: 200,
      spread: 70,
      origin: { y: -1 }
    });
    myConfetti();
  } 
  
  fiesta() {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    const canvas = this.renderer2.selectRootElement('#miCanvas');
    const myConfetti = confetti.create(canvas, {
      resize: true,
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { x: 0, y: 0.9 }
    });

    let contador = 0;
    const intervalo = setInterval(() => {
      myConfetti();
      // contador++;
      // if (contador === 50) {
      //   clearInterval(intervalo);
      // }
    }, 2500);
  }
}
