<body class="paginas-internas">
    <section class="bienvenidos">

        <div class="texto-encabezado text-xs-center">

            <div class="container">
                <h1 class="display-4  wow bounceIn">{{ 'servicios.titulo' | translate }}</h1>
                <p class="wow bounceIn" data-wow-delay=".3s">{{ 'servicios.subtitulo' | translate }}</p>

            </div>

        </div>

    </section>
    <section class="ruta p-y-1">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-right">
                    <a routerLink="/home">{{ 'menuheader.inicio' | translate }}</a> » {{ 'servicios.titulo' | translate }}

                </div>
            </div>
        </div>
    </section>

    <main class="p-y-1 lista-servicios">
        <div class="container">
            <div class="row">

                <div class="col-md-3">
                    <article class="item-servicios">
                        <img src="assets/images/asesoria.svg" alt="Servicio Asesoria" class="rotar">
                        <h3><b>{{ 'servicios.asesoria' | translate }}</b></h3>

                        <p>
                            {{ 'servicios.dasesoria' | translate }}
                        </p>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#servicio1">{{ 'servicios.moreinfo' | translate }}</button>

                    </article>
                </div>


                <div class="col-md-3">
                    <article class="item-servicios">
                        <img src="assets/images/barco.svg" alt="Servicio Importaciones Y Exportaciones" class="rotar">
                        <h3><b>{{ 'servicios.ie' | translate }}</b></h3>
                        <p>
                            {{ 'servicios.die' | translate }}
                        </p>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#servicio2">{{ 'servicios.moreinfo' | translate }}</button>

                    </article>
                </div>

                <div class="col-md-3">
                    <article class="item-servicios">
                        <img src="assets/images/logistica.svg" alt="Servicio Logística" class="rotar">
                        <h3><b>{{ 'servicios.logistica' | translate }}</b></h3>
                        <p>
                            {{ 'servicios.dlogistica' | translate }}
                        </p>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#servicio3">{{ 'servicios.moreinfo' | translate }}</button>

                    </article>
                </div>

                <div class="col-md-3">
                    <article class="item-servicios">
                        <img src="assets/images/info.svg" alt="Servicio Información" class="rotar">
                        <h3><b>{{ 'servicios.idigital' | translate }}</b></h3>

                        <p>
                            {{ 'servicios.didigital' | translate }}
                        </p>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#servicio4">{{ 'servicios.moreinfo' | translate }}</button>

                    </article>
                </div>

            </div>
        </div>
    </main>


    <!-- Modal 1  -->
    <div class="modal fade" id="servicio1" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="modal-title" style="color: rgb(255, 255, 255)"><b>{{ 'servicios.asesoria' | translate }}</b></h3>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <p [innerHTML]="'servicios.masesoria' | translate"></p>
                        </div>

                        <div class="col-md-4">
                            <img src="assets/images/asesoria.svg" alt="" width="200" class="img-fluid m-x-auto">
                        </div>


                    </div>

                </div>

            </div>
        </div>
    </div>


    <!-- Modal 2  -->
    <div class="modal fade" id="servicio2" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="modal-title" style="color: rgb(255, 255, 255)"><b>{{ 'servicios.ie' | translate }}</b></h3>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <p [innerHTML]="'servicios.mie' | translate"></p>
                        </div>

                        <div class="col-md-4">
                            <img src="assets/images/barco.svg" alt="" width="200" class="img-fluid m-x-auto">
                        </div>


                    </div>

                </div>

            </div>
        </div>
    </div>


    <!-- Modal 3  -->
    <div class="modal fade" id="servicio3" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title" style="color: rgb(255, 255, 255)"><b>{{ 'servicios.logistica' | translate }}</b></h4>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <p>
                                {{ 'servicios.mlogistica' | translate }}
                            </p>
                        </div>

                        <div class="col-md-4">
                            <img src="assets/images/logistica.svg" alt="" width="200" class="img-fluid m-x-auto m-t-2">
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>

    <!-- Modal 4  -->
    <div class="modal fade" id="servicio4" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    <h4 class="modal-title" style="color: rgb(255, 255, 255)"><b>{{ 'servicios.idigital' | translate }}</b></h4>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-8">
                            <p [innerHTML]="'servicios.midigital' | translate"></p>
                        </div>

                        <div class="col-md-4">
                            <img src="assets/images/info.svg" alt="" width="200" class="img-fluid m-x-auto m-t-2">
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</body>