import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-privacynotice',
  templateUrl: './privacynotice.component.html',
  styles: []
})
export class PrivacynoticeComponent implements OnInit {

  constructor(
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.loadingService.openLoading('');
  }

}
