export class Boletin {

    constructor(
        public titulo?: string,
        public descripcion?: string,
        public pdf?: string,
        public fechapublicado?: Date,
        public _id?: string

    ) {}
}
