<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input #input (keyup)="buscarBoletin( input.value )" type="text" class="form-control" placeholder="Buscar Boletin...">

            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="text-right">
                    <button [routerLink]="['/dashboard/boletin', 'nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                              <i class="fa fa-plus"></i> 
                              Crear Boletin
                    </button>
                </div>
                <h3 class="card-title">Boletines registrados ( <small> {{_boletinesService.totalBoletines}} </small> )</h3>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Archivo</th>
                            <th>Titulo</th>
                            <th>Descripción</th>
                            <th>Autor</th>
                            <th>Fecha de Publicación</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let boletin of boletines">
                            <td>
                                <a class="fas fa-file-pdf fa-2x" [href]="boletin.pdf | pdf:'boletin'"></a>
                                <h3 (click)="mostarModal(boletin._id)">Subir PDF</h3>
                            </td>
                            <td>{{boletin.titulo}}</td>
                            <td>{{boletin.descripcion}}</td>
                            <td>{{boletin.fechapublicado}}</td>
                            <td>
                                <button [routerLink]="['/dashboard/boletin', boletin._id]" class="btn btn-primary">
                                    <i class="fas fa-edit"></i>
                                    </button>

                                <button (click)="borrarBoletin(boletin)" class="btn btn-danger">
                                    <i class="fas fa-trash"></i>
                                    </button>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
        </div>
    </div>
</div>