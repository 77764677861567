export class Email {

    constructor(
        public nombre?: string,
        public asunto?: string,
        public email?: string,
        public mensaje?: string,
        public fecha?: string

    ) {}
}
