import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalAniversarioService {
    // tslint:disable-next-line:no-inferrable-types
    public oculto: string = 'oculto';

    public notification = new EventEmitter<any>();

    constructor() { console.log('Modal aniversario listo'); }

    ocultarModal() {
        this.oculto = 'oculto';
    }
    mostrarModal() {
        this.oculto = '';

    }
}
