import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ModalAniversarioService } from './modal-anniversario.service';
import { TranslateService } from '@ngx-translate/core';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-modal-aniversario',
  templateUrl: './modal-aniversario.component.html',
  styleUrls: ['./modal-aniversario.component.css']
})
export class ModalAniversarioComponent implements OnInit {

  seconds = 12;
  constructor(private translate: TranslateService, private renderer2: Renderer2,
    private elementRef: ElementRef,
    public modalAniversarioService: ModalAniversarioService) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.fiesta();
    const intervalId = setInterval(() => {
      this.seconds--;
      if (this.seconds === 0) {
        clearInterval(intervalId);
        this.modalAniversarioService.ocultarModal();
      }
    }, 1000);
  }

  fiesta() {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    const canvas = this.renderer2.selectRootElement('#canvasModal');
    const myConfetti = confetti.create(canvas, {
      resize: true,
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
    });
    let contador = 0;
    const intervalo = setInterval(() => {
      myConfetti();
      contador++;
      if (contador === 4) {
        clearInterval(intervalo);
      }
    }, 2500);
  }

 

  cerrarModal() {
    this.modalAniversarioService.ocultarModal();
  }
}
