<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input #input (keyup)="buscarEmbarque(input.value)" type="text" class="form-control" placeholder="Buscar embarques...">
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn" *ngIf="cargando">
    <div class="col-sm-12">
        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-refresh fa-spin fa-2x"></i>
            <br>
            <span>Espere por favor</span>
        </div>
    </div>
</div>
<div class="row animated fadeIn" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Mis Embarques</h4>
                <h6 class="card-subtitle">Disponibles</h6>
                <table class="table table-hover color-bordered-table info-bordered-table" data-filtering="true">
                    <thead>
                        <tr>
                            <th>Pedimento</th>
                            <th data-breakpoints="xs" data-title="Guia">Guia</th>
                            <th data-breakpoints="xs" data-title="Contenedor">Contenedor</th>
                            <th data-breakpoints="xs" data-title="Viaje">Viaje</th>
                            <th data-breakpoints="xs" data-title="Buque">Buque</th>
                            <th data-breakpoints="xs" data-title="Liberado">Liberado</th>
                            <th>FechaTramite</th>
                            <th data-breakpoints="all" data-title="Cliente">Cliente</th>
                            <th data-breakpoints="all" data-title="Tipo de Pedimento">Tipo de Pedimento</th>
                            <th data-breakpoints="all" data-title="Patente">Patente</th>
                            <th data-breakpoints="all" data-title="Año">Año</th>
                            <th data-breakpoints="all" data-title="Remesa">Remesa</th>
                            <th data-breakpoints="all" data-title="CvePedimento">CvePedimento</th>
                            <th data-breakpoints="all" data-title="ImportExport">ImportExport</th>
                            <th data-breakpoints="all" data-title="TipoCarga">TipoCarga</th>
                            <th data-breakpoints="all" data-title="Sello">Sello</th>
                            <th data-breakpoints="all" data-title="Observaciones">Observaciones</th>
                            <th data-breakpoints="all" data-title="FArribo">FArribo</th>
                            <th data-breakpoints="all" data-title="NoIntegracion">NoIntegracion</th>
                            <th data-breakpoints="all" data-title="CveSeccion">CveSeccion</th>
                            <th data-breakpoints="all" data-title="Seccion">Seccion</th>
                            <th data-breakpoints="all" data-title="CveAduana">CveAduana</th>
                            <th data-breakpoints="all" data-title="Aduana">Aduana</th>
                            <th data-breakpoints="all" data-title="COVE">COVE</th>
                            <th data-breakpoints="all" data-title="Placa">Placa</th>
                            <th data-breakpoints="all" data-title="Rojo1">Rojo1</th>
                            <th data-breakpoints="all" data-title="Liberado1">Liberado1</th>
                            <th data-breakpoints="all" data-title="Rojo2">Rojo2</th>
                            <th data-breakpoints="all" data-title="Liberado2">Liberado2</th>
                            <th data-breakpoints="all" data-title="Deposito">Deposito</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let embarque of embarques" data-expanded="false">
                            <td><span class="label label-danger">{{embarque.pedimento}}</span></td>
                            <td>{{embarque.Guia}}</td>
                            <td>{{embarque.contenedor}}</td>
                            <td>{{embarque.Viaje}}</td>
                            <td>{{embarque.Buque}}</td>
                            <td>{{embarque.Liberado}}</td>
                            <td>{{embarque.FechaTramite}}</td>
                            <td>{{embarque.Cliente}}</td>
                            <td>{{embarque.TipoPedimento}}</td>
                            <td>{{embarque.Patente}}</td>
                            <td><span class="label label-danger">{{embarque.Year}}</span></td>
                            <td>{{embarque.Remesa}}</td>
                            <td>{{embarque.CvePedimento}}</td>
                            <td>{{embarque.ImportExport}}</td>
                            <td>{{embarque.TipoCarga}}</td>
                            <td>{{embarque.Sello}}</td>
                            <td>{{embarque.Observaciones}}</td>
                            <td>{{embarque.FArribo}}</td>
                            <td>{{embarque.NoIntegracion}}</td>
                            <td>{{embarque.CveSeccion}}</td>
                            <td>{{embarque.seccion}}</td>
                            <td>{{embarque.cveAduana}}</td>
                            <td>{{embarque.aduana}}</td>
                            <td>{{embarque.COVE}}</td>
                            <td>{{embarque.Placa}}</td>
                            <td>{{embarque.Rojo1}}</td>
                            <td>{{embarque.Liberado1}}</td>
                            <td>{{embarque.Rojo2}}</td>
                            <td>{{embarque.Liberado2}}</td>
                            <td>{{embarque.Deposito}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <button (click)="cambiarDesde(-10)" class="btn btn-secondary">
                    <i class="fas fa-angle-left"></i> Anteriores
                  </button>
        <button (click)="cambiarDesde(10)" class="btn btn-secondary">
                      Siguientes <i class="fas fa-angle-right"></i>
                </button>
    </div>
</div>