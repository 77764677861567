<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Formulario de altas</h4>
                <form ngNativeValidate [formGroup]="forma" (ngSubmit)="registrarUsuario()" class="form p-t-20" id="loginform" action="index.html">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-user"></i></div>
                            <input formControlName="nombre" name="nombre" class="form-control" type="text" required placeholder="Nombre">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-email"></i></div>
                            <input formControlName="email" name="email" class="form-control" type="email" required placeholder="Correo">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-unlock"></i></div>
                            <input formControlName="password" name="password" class="form-control" type="password" required placeholder="Password">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-unlock"></i></div>
                            <input formControlName="password2" name="password2" class="form-control" type="password" required placeholder="Confirmar Password">
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!!forma.errors?.sonIguales && !forma.pristine">

                        <p class="text-danger">La contraseña es incorrecta</p>

                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-success p-t-0">
                                <input formControlName="condiciones" name="condiciones" id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                                <label for="checkbox-signup"> Estoy de acuerdo con los <a href="javascript:void(0)">Terminos</a></label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center p-b-20">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" type="submit">Regístrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>