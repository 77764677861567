<div class="row animated fadeIn">
    <div class="col-lg-7">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Perfil del usuario</h4>
                <h6 class="card-subtitle">{{usuario.nombre}}</h6>
                <form ngNativeValidate #f="ngForm" (ngSubmit)="guardar(f.value)" class="form p-t-20">
                    <div class="form-group">
                        <label for="exampleInputuname">Nombre de Usuario</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-user"></i></div>
                            <input [ngModel]="usuario.nombre" name="nombre" type="text" class="form-control" placeholder="Nombre de usuario" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Correo electronico</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-email"></i></div>
                            <input [ngModel]="usuario.email" name="email" type="email" class="form-control" placeholder="Correo del usuario" required>
                        </div>
                    </div>


                    <button type="submit" class="btn btn-success waves-effect waves-light m-r-10"><i class="fas fa-save"> Guardar</i></button>
                </form>
            </div>
        </div>
    </div>

    <div class="col-lg-5">
        <div class="card">
            <div class="card-body" align="center">
                <h4 class="card-title">Foto de perfil</h4>
                <h6 class="card-subtitle">{{usuario.nombre}}</h6>
                <img *ngIf="!imagenTemp" [src]="usuario.img | imagen" class="w150">
                <img *ngIf="imagenTemp" [src]="imagenTemp" class="w150">
                <input (change)="seleccionImagen($event.target.files[0])" type="file">
                <br>
                <br>

                <button (click)="cambiarImagen()" [disabled]="!imagenSubir" type="button" class="btn btn-block btn-success waves-effect waves-light m-r-10"><i class="fas fa-save"> Actulizar</i></button>
            </div>
        </div>
    </div>
</div>