<div class="fondo-negro animated fadeIn" [ngClass]="_modalContactoService.oculto">
    <div class="modal" style="display: block;" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button (click)="cerrarModal()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    <h5 class="modal-title" style="color: rgb(255, 255, 255)"><b>{{ 'contacto.mcontacto' | translate }}</b></h5>
                </div>
                <div class="modal-body">
                    <!--<form ngNativeValidate #f="ngForm" (ngSubmit)="enviarEmail(f)" class="form-horizontal form-material">-->
                    <form ngNativeValidate [formGroup]="contacto" (ngSubmit)="enviarEmail()" class="form-horizontal form-material">
                        <div class="form-group">
                            <div class="col-md-12 m-b-20">
                                <input type="text" placeholder="{{ 'contacto.mcnombre' | translate }}" formControlName="nombre" class="form-control" required>
                            </div>
                            <!--<input [(ngModel)]="email.nombre" name="nombre" type="text" class="form-control" placeholder="Tu nombre" required>-->
                            <div class="col-md-12 m-b-20">
                                <input type="text" placeholder="{{ 'contacto.mcasunto' | translate }}" formControlName="asunto" class="form-control" required>
                            </div>
                            <!--<input [(ngModel)]="email.asunto" name="asunto" type="text" class="form-control" placeholder="Asunto" required>-->
                            <div class="col-md-12 m-b-20">
                                <input type="email" placeholder="{{ 'contacto.mccorreo' | translate }}" formControlName="email" class="form-control" required>
                            </div>
                            <!--<input [(ngModel)]="email.email" name="email" type="email" class="form-control" placeholder="Tu correo" required>-->
                            <div class="col-md-12 m-b-20">
                                <textarea type="text" placeholder="{{ 'contacto.mcmensaje' | translate }}" formControlName="mensaje" class="form-control" rows="5" required></textarea>
                            </div>
                            <div appRecaptcha key="6LcioWgUAAAAAH_t_dISpkOxJAzPW6beEFSvtG0K" formControlName="captcha" required></div>
                            <!--<textarea [(ngModel)]="email.mensaje" name="mensaje" type="text" class="form-control" rows="5" placeholder="¿Cómo podemos ayudarte?" required></textarea>-->
                            <!--<div class="col-md-12 m-b-20">
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i class="ion-upload m-r-5"></i>Adjuntar Archivo</span>
                                    <input type="file" class="upload"> </div>
                            </div>-->
                        </div>
                        <div class="modal-footer">
                            <button type="submit" [disabled]="contacto.invalid" class="btn btn-primary">{{ 'contacto.mcboton' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>