import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

    constructor() { }

    openLoading(info: string, autoClose = true) {
        let modal = document.getElementById("myModalLoading");
        let infoModal = document.getElementById("myModalInfoLoading");
        if (!modal) return;
        modal.style.display = "none";
        // infoModal.innerText = info;
        if (autoClose) setTimeout(() => { this.closeLoading(); }, 900);
    }

    closeLoading() {
        let modal = document.getElementById("myModalLoading");
        if (!modal) return;
        modal.style.display = "none";
    }
}