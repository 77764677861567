<div class="fondo-transparente animated fadeIn" [ngClass]="modalAniversarioService.oculto" (click)="cerrarModal()">
  <canvas id="canvasModal" class="canvasConfeti"></canvas>
  <div class="modal" style="display: block; background-color: transparent !important; width: 103% !important;"
    tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="background-color: rgba(255, 255, 255, .7) !important;">
        <div class="modal-body" style="background-color: transparent !important;">
          <div class="contenedor-imagen">
            <img src="assets/images/logo_50_aniversario_patente_3814_ajustado.png" alt="Descripción de la imagen">
            <p>Se cerrará en {{seconds}} segundos</p>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <p>Se cerrará en {{seconds}} segundos</p>
        </div> -->
      </div>
    </div>
  </div>
</div>