import { ModalContactoService } from '../../components/modalcontacto/modalcontacto.service';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styles: []
})
export class ContactoComponent implements OnInit {

  constructor(public _modalContactoSevice: ModalContactoService, private loadingService: LoadingService) { }

 ngOnInit() {
   this.loadingService.openLoading('');
 }
 mostarModal() {
  this._modalContactoSevice.mostrarModal();
}
}
