<body class="paginas-internas">
    <section class="bienvenidos">
        <div class="texto-encabezado text-xs-center">

            <div class="container">
                <h1 class="display-4">{{ 'servicios.asesoria' | translate }}</h1>
                <p class="wow bounceIn" data-wow-delay=".3s"></p>
            </div>
        </div>
    </section>

    <section class="ruta p-y-1">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-right">
                    <a routerLink="/home">{{ 'menuheader.inicio' | translate }}</a> » {{ 'servicios.asesoria' | translate }}
                </div>
            </div>
        </div>
    </section>
    <main class="p-y-1">
        <div class="container">
            <div class="row">
                <article class="col-md-8">
                    <!--<h2>Transporte y Logística REIM</h2>-->
                    <p>
                        {{ 'servicios.dasesoria' | translate }}
                    </p>
                </article>
                <aside class="reim col-md-4">
                    <img src="assets/images/pages/asesoria.png" alt="REIM">
                </aside>
            </div>
        </div>
    </main>
</body>