<section class="bienvenidos" style="height: 108vh;">

    <div class="texto-encabezado text-xs-center" style="align-items: end !important;">

        <div class="container">

            <h1 class="display-4 wow bounceIn">{{ 'home.titulo' | translate }}</h1>
            <p class="wow bounceIn" data-wow-delay=".3s">{{anio}} {{ 'home.lema' | translate }}</p>
            <a routerLink="/contacto" class="boton btn-primary btn-lg">{{ 'home.btncontacto' | translate }}</a>
        </div>
    </div>

    <div class="flecha-bajar text-xs-center">
        <a data-scroll href="#home"> <i class="fa fa-angle-down" aria-hidden="true"></i></a>
    </div>
</section>

<section class="home p-y-1" id="home">

    <div class="container">

        <div class="row">

            <div class="col-md-8 col-xl-9 wow bounceIn" data-wow-delay=".3s">
                <h2 class="h3 text-xs-center text-md-left font-weight-bold">{{ 'home.titulo' | translate }}</h2>
                <p [innerHTML]="'seccion.parrafo' | translate"></p>
            </div>
            <div class="col-md-4 col-xl-3 wow bounceIn" data-wow-delay=".6s">
                <img src="assets/images/agencia.svg" alt="La agencia">
            </div>
        </div>
    </div>


</section>

<section class="tu-mejor-eleccion p-y-1">
    <div class="container">

        <h2 class="h3 text-xs-center font-weight-bold">{{ 'seccioneleccion.porque' | translate }} <span>{{ 'seccioneleccion.mejoreleccion' | translate }}</span></h2>
        <p class="text-xs-center">{{ 'seccioneleccion.respuesta' | translate }}</p>

        <div class="row">
            <ul class="col-xs-6 col-lg-4 text-xs-center text-lg-left">
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay=".3s">
                    <i class="fas fa-map-marker-alt" aria-hidden="true"></i>

                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.rastreo' | translate }} </h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.drastreo' | translate }}</p>
                    </div>
                </li>
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay=".7s">
                    <i class="fas fa-certificate" aria-hidden="true"></i>
                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.certificado' | translate }}</h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.dcertificado' | translate }}</p>
                    </div>
                </li>
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay="1.1s">

                    <i class="fas fa-sitemap" aria-hidden="true"></i>

                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.integrador' | translate }} </h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.dintegrador' | translate }}</p>
                    </div>
                </li>
            </ul>

            <div class="hidden-md-down col-lg-4">
                <img src="assets/images/mundo.svg" alt="Mundo">
            </div>

            <ul class="col-xs-6 col-lg-4 text-xs-center text-lg-right">
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay=".5s">
                    <i class="fas fa-shield-alt" aria-hidden="true"></i>
                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.seguridad' | translate }}</h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.dseguridad' | translate }}</p>
                    </div>
                </li>
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay=".9s">
                    <i class="fas fa-server" aria-hidden="true"></i>
                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.infraestructuta' | translate }}</h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.dinfraestructuta' | translate }}</p>
                    </div>
                </li>
                <li class="wow zoomIn" data-wow-duration=".3s" data-wow-delay="1.3s">
                    <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                    <div class="contenedor-eleccion">
                        <h4>{{ 'seccioneleccion.web' | translate }}</h4>
                        <p class="hidden-md-down">{{ 'seccioneleccion.dweb' | translate }}</p>
                    </div>
                </li>
            </ul>
        </div>

    </div>


</section>

<main class="servicios p-y-1">
    <div class="container">
        <h2 class="nservicios text-xs-center font-weight-bold">{{ 'servicios.titulo' | translate }}</h2>

        <div class="row">
            <article class="col-md wow bounceInLeft" data-wow-delay=".3s">
                <img src="assets/images/asesoria.svg" alt="Servicio Asesoria">
                <h3><a routerLink="/asesoria">{{ 'servicios.asesoria' | translate }}</a></h3>
                <p class="hidden-sm-down">{{ 'servicios.dasesoria' | translate }}</p>
                <a routerLink="/asesoria" class="btn btn-secondary hidden-sm-down">{{ 'servicios.moreinfo' | translate }}</a>

            </article>

            <article class="col-md wow bounceInUp" data-wow-delay=".6s">
                <img src="assets/images/barco.svg" alt="Servicio Importaciones y exportaciones">
                <h3><a routerLink="/importacionesyexportaciones">{{ 'servicios.ie' | translate }}</a></h3>
                <p class="hidden-sm-down">{{ 'servicios.die' | translate }}</p>
                <a routerLink="/importacionesyexportaciones" class="btn btn-secondary hidden-sm-down">{{ 'servicios.moreinfo' | translate }}</a>
            </article>

            <article class="col-md wow bounceInRight" data-wow-delay="1s">
                <img src="assets/images/logistica.svg" alt="Logística">
                <h3><a routerLink="/logistica">{{ 'servicios.logistica' | translate }}</a></h3>
                <p class="hidden-sm-down">{{ 'servicios.dlogistica' | translate }}</p>
                <a routerLink="/logistica" class="btn btn-secondary hidden-sm-down">{{ 'servicios.moreinfo' | translate }}</a>
            </article>

            <article class="col-md wow bounceInRight" data-wow-delay="1s">
                <img src="assets/images/info.svg" alt="Servicio Información">
                <h3><a routerLink="/informacion">{{ 'servicios.idigital' | translate }}</a></h3>
                <p class="hidden-sm-down">{{ 'servicios.didigital' | translate }}</p>
                <a routerLink="/informacion" class="btn btn-secondary hidden-sm-down">{{ 'servicios.moreinfo' | translate }}</a>
            </article>

        </div>
    </div>

</main>


<section *ngIf="false" class="ultimos-proyectos p-y-1">
    <div class="container">
        <h2 class="text-xs-center font-weight-bold">Legislación Aduanera</h2>
        <li>
            <a class="font-weight-bold" href="assets/documentos/Decreto_DOF_14072021.pdf" target="_blank">
                Decreto DOF 14/07/2021 <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i> </a>
        </li>
        <li>
            <a class="font-weight-bold" href="assets/documentos/Ley_Aduanera_2018.pdf" target="_blank">
                Ley Aduanera 2018 <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i> </a>
        </li>
        <li>
            <a class="font-weight-bold" href="assets/documentos/REGLAMENTO_LEY_ADUANERA_2018.pdf" target="_blank">
                Reglamento Ley Aduanera 2018 <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i>  </a>
        </li>
        <li>
            <a class="font-weight-bold" href="assets/documentos/RGCE_2018_Publicacion_Original.pdf" target="_blank">
                RGCE Para 2018 Publicación Original <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i>  </a>
        </li>
        <li>
            <a class="font-weight-bold" href="assets/documentos/DOF_Diario_Oficial_Federacion.pdf" target="_blank">
                    DOF Diario Oficial de la Federación <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i>  </a>
        </li>
        <li>
            <a class="font-weight-bold" href="assets/documentos/Comunicado_Forma_96._INTERNET.pdf" target="_blank">
                    Comunicado Forma 96 <i class="fas fa-file-pdf fa-2x" aria-hidden="true"> </i>  </a>
        </li>
        <!--<h2 class="text-xs-center font-weight-bold">{{ 'boletines.titulo' | translate }} ( <small> {{_boletinesService.totalBoletines}} </small> )</h2>

        <div *ngFor="let boletin of boletines" class="list-group">
            <a [href]="boletin.pdf | pdf:'boletin'" class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{boletin.titulo}}</h5>
                    <small>{{boletin.fechapublicado}}</small>
                </div>
                <p class="mb-1">{{boletin.descripcion}}</p>
                <small>Donec id elit non mi porta.</small>
            </a>-->
        <!-- <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">¿Qué es un arancel preferencial?</h5>
                    <small class="text-muted">25/07/2017</small>
                </div>
                <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                <small class="text-muted">Donec id elit non mi porta.</small>
            </a>
            <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Certificado de origen, un acta de nacimiento</h5>
                    <small class="text-muted">25/07/2016</small>
                </div>
                <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                <small class="text-muted">Donec id elit non mi porta.</small>
            </a>-->

    </div>
</section>