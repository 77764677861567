<body class="paginas-internas">
    <section class="bienvenidos">

        <div class="texto-encabezado text-xs-center">

            <div class="container">
                <h1 class="display-4 wow bounceIn">{{ 'menuheader.contacto' | translate }}</h1>
                <p class="wow bounceIn" data-wow-delay=".3s">{{ 'contacto.subtitulo' | translate }}</p>

            </div>

        </div>

    </section>
    <section class="ruta p-y-1">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-right">
                    <a routerLink="/home">{{ 'menuheader.inicio' | translate }}</a> » {{ 'menuheader.contacto' | translate }}

                </div>
            </div>
        </div>
    </section>
    <main class="p-y-1">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <h2><b>{{ 'contacto.reachus' | translate }}</b></h2>

                    <b><p [innerHTML]="'contacto.reachusc' | translate"></p></b>

                    <!--<h2><b>Directivos</b></h2>
                            <p>
                                <a style="font-size:2em; color:Tomato" class="fas fa-envelope" href="mailto:atencionclientes@mieryteran.com.mx"></a> A.A. Bernardo Mier y Terán Gutiérrez</p>
                            <p>
                                <a style="font-size:2em; color:Tomato" class="fas fa-envelope" href="mailto:atencionclientes@mieryteran.com.mx"></a> A.A. Pamela Mier y Terán Díaz</p>
                            <p>
                                <a style="font-size:2em; color:Tomato" class="fas fa-envelope" href="mailto:atencionclientes@mieryteran.com.mx"></a> L.N.I. Gerardo Mier y Terán San Román</p>
                            <h2><b>Operaciones</b></h2>
                            <p>
                                <a style="font-size:2em; color:Tomato" class="fas fa-envelope" href="mailto:atencionclientes@mieryteran.com.mx"></a> LCI Cynthia Martín del Campo</p>
                            <h2><b>Comercial</b></h2>
                            <p>
                                <a style="font-size:2em; color:Tomato" class="fas fa-envelope" href="mailto:atencionclientes@mieryteran.com.mx"></a> LNRI Andrea Cocom Gamboa</p>-->
                </div>

                <div class="col-sm">
                    <h2><b>{{ 'contacto.ubicacion' | translate }}</b></h2>
                    <b>{{ 'contacto.progreso' | translate }}</b>
                    <b><p><a href="tel:+529699350042">969 935 00 42</a></p></b>
                    <b><p>{{ 'contacto.calleprogreso' | translate }}</p></b>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.705268251397!2d-89.61796168506848!3d20.964347986033296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5673b415555555%3A0x5fb141e375da5b22!2sAgencia+Aduanal+Mier+Y+Ter%C3%A1n!5e0!3m2!1ses-419!2smx!4v1498844229089"
                        width="400" height="100" frameborder="0" style="border: 0px; pointer-events: auto;" allowfullscreen=""></iframe>

                    <b>{{ 'contacto.aeropuerto' | translate }}</b>
                    <b><p><a href="tel:9999461868">999 946 18 68</a></p></b>
                    <!-- <b><p><a href="tel:9999461869">999 946 18 69 <small style="color: #EF2E1F !important;">({{ 'contacto.telFueraServicio' | translate }})</small></a></p></b>
                    <b><p><a href="tel:9999461870">999 946 18 70 <small style="color: #EF2E1F !important;">({{ 'contacto.telFueraServicio' | translate }})</small></a></p></b> -->
                    <b><p><a href="tel:9999461869">999 946 18 69</a></p></b>
                    <b><p><a href="tel:9999461870">999 946 18 70</a></p></b>
                    <b><p><a href="tel:9999461871">999 946 18 71</a></p></b>
                    <b><p><a href="tel:9999461873">999 946 18 73</a></p></b>
                    <!-- <b><p><a href="tel:+52(999) 942 15 30">(999) 942 15 30</a></p></b> -->
                    <b><p>{{ 'contacto.calleaeropuerto' | translate }}</p></b>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d931.6169501575413!2d-89.67317717083903!3d20.93372085171262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5672f7c6eab49d%3A0xb66772f533ecb6f7!2sAgencia%20Aduanal%20Mier%20y%20Ter%C3%A1n%20Aeropuerto!5e0!3m2!1sen!2smx!4v1596043450222!5m2!1sen!2smx"
                        width="400" height="100" frameborder="0" style="border:0" allowfullscreen></iframe>

                </div>
                <div class="col-sm">
                    <h2><b>{{ 'contacto.mailus' | translate }}</b></h2>
                    <button (click)="mostarModal()" type="button" class="btn btn-info btn-rounded" data-toggle="modal" data-target="#add-contact">{{ 'contacto.btncontactanos' | translate }}</button>
                    <!--<h1>Detalles de contacto</h1>-->
                    <h2><b>Atención a Clientes</b></h2>

                    <a style="font-size:1.5em; color:Tomato" href="mailto:atencionclientes@mieryteran.com.mx" class="fas fa-envelope"><b> atencionclientes</b></a>

                </div>
                <div class="col-md-5">
                </div>

            </div>
        </div>
    </main>


    <!--<a data-scroll class="ir-arriba" href="#encabezado"><i class="fa  fa-arrow-circle-up" aria-hidden="true"> </i> </a>-->

    <!-- Carga de archivos  JS -->

    <script type="text/javascript">
        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        })
    </script>
</body>