<body class="paginas-internas">
    <section class="bienvenidos">
        <div class="texto-encabezado text-xs-center">

            <div class="container">
                <h1 class="display-4">{{ 'nosotros.titulo' | translate }}</h1>
                <p class="wow bounceIn" data-wow-delay=".3s">{{ 'nosotros.subtitulo' | translate }}</p>

            </div>

        </div>

    </section>

    <section class="ruta p-y-1">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-right">
                    <a routerLink="/home">{{ 'menuheader.inicio' | translate }}</a> » {{ 'menuheader.nosotros' | translate }}

                </div>
            </div>
        </div>
    </section>
    <main class="p-y-1">
        <div class="container">
            <div class="row">
                <article class="col-md-8">
                    <h2><b>{{ 'home.titulo' | translate }}</b></h2>
                    <p [innerHTML]="'nosotros.parrafo1' | translate"></p>

                    <!--<aside class="manuelmyt col-md-4">
                        <img src="assets/images/nosotros/mier-y-teran-agencia-aduanal.jpg" alt="Nosotros">
                    </aside>-->
                    <p [innerHTML]="'nosotros.parrafo2' | translate"></p>

                    <h2><b>{{ 'nosotros.filosofia' | translate }}</b></h2>
                    <p [innerHTML]="'nosotros.contenidof' | translate"></p>



                    <div id="accordion" role="tablist" aria-multiselectable="true">

                        <div class="panel panel-default">

                            <h4 class="panel-heading">
                                <a data-toggle="collapse" data-parent="#accordion" href="#tab-mision">
                                    <div style="font-size: 1.5rem;"><i class="fa fa-angle-double-down" aria-hidden="true"> {{ 'nosotros.mision' | translate }}</i></div>
                                </a>

                            </h4>
                            <div id="tab-mision" class="panel-collapse collapse in">
                                <p [innerHTML]="'nosotros.contenidom' | translate"></p>

                            </div>
                        </div>


                        <div class="panel panel-default">
                            <h4 class="panel-heading">

                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#tab-vision">
                                    <div style="font-size: 1.5rem;"><i class="fa fa-angle-double-down" aria-hidden="true"> {{ 'nosotros.vision' | translate }}</i></div>
                                </a>

                            </h4>
                            <div id="tab-vision" class="panel-collapse collapse">
                                <p [innerHTML]="'nosotros.contenidov' | translate"></p>
                            </div>
                        </div>


                        <div class="panel panel-default">
                            <h4 class="panel-heading">

                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#tab-valores">
                                    <div style="font-size: 1.5rem;"><i class="fa fa-angle-double-down" aria-hidden="true"> {{ 'nosotros.valores' | translate }}</i></div>
                                </a>

                            </h4>
                            <div id="tab-valores" class="panel-collapse collapse">
                                <p [innerHTML]="'nosotros.contenidova' | translate"></p>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <h4 class="panel-heading">

                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#tab-politicas">
                                    <div style="font-size: 1.5rem;"><i class="fa fa-angle-double-down" aria-hidden="true"> {{ 'nosotros.politica' | translate }}</i></div>
                                </a>

                            </h4>
                            <div id="tab-politicas" class="panel-collapse collapse">
                                <p [innerHTML]="'nosotros.contenidop' | translate"></p>
                            </div>
                        </div>

                    </div>

                </article>
                <aside class="nosotrosmyt col-md-4">
                    <h2><b>{{ 'nosotros.patentes' | translate }}</b></h2>
                    <p>3814 A.A. Bernardo Mier y Terán Gutiérrez
                        <span class="badge bg-light" style="position: absolute; margin-left: 4px; margin-top: -11px; font-size: 16px !important; color: #0088c4;
                                        font-weight: bold;">{{'nosotros.50_aniversario' | translate}} 🥳🎉</span>
                    </p>
                    <p>1756 A.A. Pamela Mier y Terán Díaz</p>
                    <!--<h2><b>{{ 'nosotros.directivos' | translate }}</b></h2>
                    <p><img src="assets/images/nosotros/bernardo-mier-y-teran-agencia-aduanal.jpg" alt="Nosotros"></p>-->
                    <!-- <h2><b>{{ 'nosotros.personal' | translate }}</b></h2>
                    <p><img src="assets/images/nosotros/staff.jpg" alt="Nosotros"></p> -->
                </aside>



            </div>
        </div>
    </main>
    <section class="certificados">
        <div class="container">

            <h2 class="h3 text-xs-center font-weight-bold">{{ 'nosotros.certificados' | translate }} <span></span></h2>

            <div class="row">

                <div class="col-md-12 flex item-strech">
                    <div class="certificacion">
                        <div class="content-cert font-sans-normal txt-white">
                            <br>
                            <div class="img-cert">
                                <img style="object-fit: contain !important;" src="assets/images/certificados/llc_9001.png">
                            </div>
                            <h2 class="txt-center"><br>
                                {{ 'nosotros.tituloc' | translate }}</h2>
                            <p class="txt-justify" [innerHTML]="'nosotros.contenidoc' | translate"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <embed src="assets/certificados/CERTIFICADO2021_2024.pdf#toolbar=0&navpanes=0&scrollbar=0" type="application/pdf" width="100%" height="480px" />
                </div>
            </div>
        </div>
    </section>
</body>