<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">


    <app-headerdash></app-headerdash>
    <app-sidebar></app-sidebar>

    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">

        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">

            <app-breadcrumbs></app-breadcrumbs>

            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->

            <router-outlet></router-outlet>

            <!-- 
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    This is some text within a card block.
                                </div>
                            </div>
                        </div>
                    </div> -->
            <!-- ============================================================== -->
            <!-- End Page Content -->
            <!-- ============================================================== -->

        </div>
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->


    </div>
    <!-- ============================================================== -->
    <!-- END Page wrapper  -->
    <!-- ============================================================== -->

</div>
<!--modal upload-->
<app-modal-upload></app-modal-upload>
<app-modaluploadpdf></app-modaluploadpdf>