<footer class="piedepagina p-y-1" role="contentinfo">
    <div class="container">
        <p>{{anio}} © MIER y TERÁN {{ 'footer.derechos' | translate }}</p>
        <ul class="redes-sociales" *ngIf="false">
            <li><a href="https://www.facebook.com/Mier-y-Ter%C3%A1n-Agencia-Aduanal-191675187709928/"><i class="fab fa-facebook" aria-hidden="true"> </i>  </a></li>
            <li><a href="https://twitter.com/aamieryteran"><i class="fab fa-twitter" aria-hidden="true"></i> </a></li>
        </ul>
        <div class="col-sm-12 links-footer">
            <label>{{ 'home.titulo' | translate }}</label> |
            <a routerLink="/aviso-privacidad"
                style="color: #12afaf; font-size: 18px; font-weight: bold; opacity: 1 !important;">
                {{'footer.aviso' |translate }}
            </a>
            <a *ngIf="false" href="https://github.com/ajpy08/mieryteran/issues" style="color: rgb(167, 166, 166)" target="_blank">
                {{'footer.reporte' | translate }}
            </a>
        </div>
    </div>
</footer>