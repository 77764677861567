<header class="encabezado navbar-fixed-top" role="banner" id="encabezado">
  
    <div class="container">

        <!--========Logo======== -->
        <a routerLink="/home" class="logo">
            <div class="contenedor-canvas-imagen">
                <canvas id="miCanvas"></canvas>
                <img src="assets/images/logo_50_aniversario_patente_3814.png" alt="Descripción de la imagen">
              </div>
            <!-- <img src="assets/images/logo_50_aniversario_patente_3814.png" alt="Logo del sitio" class="logo-myt"> -->
        </a>
        <!--========Fin Logo======== -->

        <!--========Botones======== -->
        <!--<button type="button" class="boton-key" data-toggle="collapse" data-target="#bloque-key" routerLink="/login" aria-expanded="false">
      <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
  </button>-->
        <button *ngIf="false" type="button" class="boton-key" data-toggle="collapse" data-target="#bloque-key"
            onclick="window.location.href='http://www.mieryteran.com.mx/opermyt/index.php'" aria-expanded="false">
            <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
        </button>

        <button type="button" class="boton-menu hidden-md-up" data-toggle="collapse" data-target="#menu-principal"
            aria-expanded="false">
            <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
        <div style="font-size:2em; color:tomato; margin-left: 0px !important" class="boton-key">
            <i class="fas fa-globe"></i>
        </div>
        <button style="margin-left: 0px !important;" type="button" (click)="useLanguage('es')" class="boton-key boton btn-sm"><img src="assets/i18n/es.png"
                alt="mexico"></button>

        <button style="margin-left: 0px !important;" type="button" (click)="useLanguage('en')" class="boton-key boton btn-sm"><img src="assets/i18n/en.png"
                alt="usa"></button>
        <!--========Fin Botones======== -->

        <!--========menu de navegacion======== -->
        <nav id="menu-principal" class="collapse">
            <ul>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="fas fa-home fa-1x" routerLink="/home"> {{ 'menuheader.inicio' | translate }}</a>
                </li>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="fas fa-user fa-1x" routerLink="/nosotros"> {{ 'menuheader.nosotros' | translate }}</a>
                </li>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="fas fa-clipboard-list fa-1x" routerLink="/servicios"> {{ 'menuheader.servicios' | translate }}</a>
                </li>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="fas fa-envelope fa-1x" routerLink="/contacto"> {{ 'menuheader.contacto' | translate}}</a>
                </li>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="fa fa-shield fa-1x" routerLink="/aviso-privacidad"> {{ 'menuheader.aviso' | translate}}</a>
                </li>
                <!-- <li><a class="fas fa-users fa-lg" routerLinkActive="active" routerLink="/bolsatrabajo"> Bolsa De Trabajo</a></li>-->
            </ul>
        </nav>

        <!--========Fin menu de navegacion======== -->
    </div>
    <!--Bolsa de trabajo
    <div class="container">

        <div class="buscador">
            <ul class="ulRedes">
                <li class="liLocaliza">
                    <a href="bolsa-de-trabajo.html">
                        <img src="assets/images/unete-icon.png" alt="Bolsa De Trabajo">
                        <div class="divlocaliza">Únete a nuestro equipo</div>
                    </a>
                </li>
            </ul>
        </div>
    </div>-->
    <!-- <p (click)="click()" style="color: red;">Algo</p> -->
</header>