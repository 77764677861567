<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(assets/images/fondo-encabezado-grande.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" id="loginform" (ngSubmit)="ingresar(f)">
                <a href="javascript:void(0)" class="text-center db"><img src="assets/images/logo.svg" alt="Home" /><br/></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <input [ngModel]="email" name="email" class="form-control" type="email" required placeholder="Correo del usuario">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input ngModel name="password" class="form-control" type="password" required="" placeholder="Contraseña">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input [(ngModel)]="recuerdame" name="recuerdame" id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Recuerdame </label>
                        </div>
                        <a href="http://localhost:3000/forgot_password" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Olvidó su contraseña?</a></div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Ingresar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 m-t-10">
                        <!--<div class="social">
                            <a href="javascript:void(0)" class="btn btn-facebook" data-toggle="tooltip" title="Login with Facebook"> <i aria-hidden="true" class="fa fa-facebook"></i> </a>
                            <a href="javascript:void(0)" class="btn btn-googleplus" data-toggle="tooltip" title="Login with Google"> <i aria-hidden="true" class="fa fa-google-plus"></i> </a>
                        </div>-->
                    </div>
                </div>
                <!-- <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        No tiene una cuenta? <a href="pages-register2.html" class="text-primary m-l-5"><b>Registrate</b></a>
                    </div>
                </div>-->
            </form>
            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recover Password</h3>
                        <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" required="" placeholder="Email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->